
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { QuantityHistoryChangeRequest } from "@/models/quantity_history";
import {
  IQuantityHistory,
  IQuantityHistoryChangeRequest,
  IQuantityHistoryListRequest
} from "@/types/quantity_history";

@Component({})
export default class extends Vue {
  //パラメータ定義
  //quantity_history
  @Action("quantity_history/adminGetList")
  public getQuantityHistoryList!: (
    list_request: IQuantityHistoryListRequest
  ) => Promise<boolean>;

  @Action("quantity_history/adminUpdate")
  public update!: (
    update_request: IQuantityHistoryChangeRequest
  ) => Promise<boolean>;

  @Getter("quantity_history/single")
  public quantity_history!: IQuantityHistory;

  @Mutation("quantity_history/clear")
  public clearQuantityHistory!: () => void;

  // received_order
  @Action("received_order/adminReceivedOrderIsShipmentComplete")
  public adminReceivedOrderIsShipmentComplete!: (
    received_order_id: number
  ) => Promise<boolean>;

  // order
  @Action("order/adminIsStoringComplete")
  public getIsStoringComplete!: (order_id: number) => Promise<boolean>;

  //watch
  @Watch("update_request.product_shipment.quantity")
  //出荷売単価、仕入単価合計計算
  public shipmentAmount() {
    this.update_request.product_shipment.amount = Number(
      (
        this.update_request.product_shipment.quantity *
        this.update_request.product_shipment.buying_unit_price
      ).toFixed(2)
    );
    this.update_request.product_shipment.selling_unit_amount = Number(
      (
        this.update_request.product_shipment.quantity *
        this.update_request.product_shipment.selling_unit_price
      ).toFixed(2)
    );
  }

  @Watch("update_request.product_shipment.quantity")
  // 出荷数量の変更差分計算
  public shipmentDifferenceQuantity() {
    this.shipment_difference_quantity =
      this.update_request.product_shipment.quantity -
      this.product_shipment_quantity;
  }

  @Watch("shipment_difference_quantity")
  // 出荷数量の変更差分によるステータス変更
  public changeIsStatus() {
    if (this.shipment_difference_quantity == 0) {
      this.is_product_shipment_modify = false;
    } else {
      this.is_product_shipment_modify = true;
    }
  }

  @Watch("update_request.product_received_order.quantity")
  //受注売単価合計計算
  public receivedOrderAmount() {
    if (this.submit_click) return;
    this.update_request.product_received_order.amount = Number(
      (
        (this.update_request.product_received_order.quantity ?? 0) *
        (this.update_request.product_received_order.selling_unit_price ?? 0)
      ).toFixed(2)
    );
  }

  // パラメータ定義
  public list_request: IQuantityHistoryListRequest = {
    shipment_id: 0,
    product_shipment_id: 0
  };
  public update_request: IQuantityHistoryChangeRequest =
    new QuantityHistoryChangeRequest();

  //変数定義
  public shipment_difference_quantity = 0;
  public product_shipment_quantity = 0;
  public received_order_quantity = 0;
  public storing_quantity = 0;
  public order_product_quantity = 0;
  public movements_quantity: number[] = [];
  public is_product_shipment_modify = false;
  public is_product_received_order_modify = false;
  public is_movements_modify = false;
  public is_storing_modify = false;
  public product_received_order_check_box = false;
  public movements_check_box = false;
  public storing_check_box = false;
  public order_product_check_box = false;
  public submit_dialog = false;
  public valid = true;
  public lazy = false;
  public submit_click = false;

  //ルール設定
  public rules = {
    shipment_quantity: [
      (v: number) => !!v || "数量は必須です",
      (v: number) => v > 0 || "0以下は入力できません",
      (v: string) => /^([1-9]\d*|0)$/.test(v) || "整数で入力してください"
    ]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.clearQuantityHistory();
    this.list_request.shipment_id = Number(this.$route.params.shipment_id);
    this.list_request.product_shipment_id = Number(
      this.$route.params.product_shipment_id
    );

    await this.getQuantityHistoryList(this.list_request);

    this.update_request = new QuantityHistoryChangeRequest(
      this.quantity_history
    );

    // 出荷、受注、倉庫移動、入荷、発注の初期数量を定義
    if (this.quantity_history.movements.length) {
      this.quantity_history.movements.forEach(movement => {
        this.movements_quantity.push(movement.quantity || 0);
      });
    }

    this.product_shipment_quantity =
      this.quantity_history.product_shipment.quantity;

    this.received_order_quantity =
      this.quantity_history.product_received_order.quantity || 0;

    this.storing_quantity = this.quantity_history.storing.quantity;

    this.order_product_quantity = this.quantity_history.order_product.quantity;
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  public async submit() {
    this.submit_click = true;
    this.submit_dialog = false;
    let result = false;

    // 入荷数量に変更がない場合はstoring要素を削除
    if (!this.is_storing_modify) {
      this.update_request.storing = {};
    }

    // 倉庫移動に変更がない場合はmovements要素を削除
    if (!this.is_movements_modify) {
      this.update_request.movements = [];
    }

    // 更新
    result = await this.update(this.update_request);

    // 発注ステータス更新
    if (result) {
      result = await this.getIsStoringComplete(
        this.quantity_history.order_product.order_id
      );
    }

    // 受注ステータス更新
    if (result) {
      result = await this.adminReceivedOrderIsShipmentComplete(
        this.quantity_history.product_received_order.received_order_id
      );
    }

    // 前の画面に戻る
    if (result) {
      this.$router.go(-1);
    }
  }

  // 出荷数量が変更された場合の受注数量挙動
  public productReceivedOrderModify() {
    // 出荷数量が受注数量より多い場合
    if (
      this.update_request.product_shipment.quantity >
      this.received_order_quantity
    ) {
      this.is_product_received_order_modify = true;
      this.product_received_order_check_box = true;
    }
    // 出荷数量が受注数量より少ない場合
    if (
      this.update_request.product_shipment.quantity <
      this.received_order_quantity
    ) {
      this.product_received_order_check_box = false;
    }
    // 出荷数量の変更差分が0の場合
    if (this.shipment_difference_quantity == 0) {
      this.is_product_received_order_modify = false;
      this.product_received_order_check_box = false;
    }
    // 受注数量にチェックが入っている場合、出荷数量の変更差分を足す
    if (this.is_product_received_order_modify) {
      this.update_request.product_received_order.quantity =
        this.received_order_quantity + this.shipment_difference_quantity;
    }
    // 受注数量にチェックが入っていない場合、受注数量を初期数量に戻す
    if (!this.is_product_received_order_modify) {
      this.update_request.product_received_order.quantity =
        this.received_order_quantity;
    }
  }

  // 出荷数量が変更された場合の倉庫移動有無でのメソッド割当
  public movementsAndStoringModify() {
    // 倉庫移動がある場合
    if (this.update_request.movements.length) {
      this.movementsModify();
    }
    // 倉庫移動がない場合
    if (!this.update_request.movements.length) {
      this.storingModify();
    }
    this.orderProductModify();
  }

  //出荷数量及び入荷数量変更に伴う発注数量の挙動
  public orderProductModify() {
    // 入荷数量が発注数量より多い場合
    if (
      this.update_request.storing.quantity >
      this.quantity_history.order_product.quantity
    ) {
      this.update_request.storing.is_order_product_modify = true;
      this.order_product_check_box = true;
    }
    // 入荷数量が発注数量より少ない場合
    if (
      this.update_request.storing.quantity <
      this.quantity_history.order_product.quantity
    ) {
      this.order_product_check_box = false;
    }
    // 出荷数量の変更差分が0の場合
    if (this.shipment_difference_quantity == 0) {
      this.update_request.storing.is_order_product_modify = false;
      this.order_product_check_box = false;
    }
    // 発注数量にチェックが入っている場合、出荷数量の変更差分を足す
    if (this.update_request.storing.is_order_product_modify) {
      this.order_product_quantity =
        this.quantity_history.order_product.quantity +
        this.shipment_difference_quantity;
    }
    // 発注数量にチェックが入っていない場合、発注数量を初期数量に戻す
    if (!this.update_request.storing.is_order_product_modify) {
      this.order_product_quantity =
        this.quantity_history.order_product.quantity;
    }
  }

  // 出荷数量が変更された場合の入荷数量挙動
  public storingModify() {
    const latest_inventory = this.update_request.storing.stock.quantity;
    // 出荷数量の差分が入荷数量の現在庫より多い場合
    if (latest_inventory < this.shipment_difference_quantity) {
      this.is_storing_modify = true;
      this.storing_check_box = true;
    }
    // 出荷数量の差分が入荷数量の現在庫より少ない場合
    if (latest_inventory > this.shipment_difference_quantity) {
      this.storing_check_box = false;
    }
    // 出荷数量の変更差分が0の場合
    if (this.shipment_difference_quantity == 0) {
      this.is_storing_modify = false;
      this.storing_check_box = false;
    }
    // 入荷数量にチェックが入っている場合、出荷数量の変更差分を足す
    if (this.is_storing_modify) {
      this.update_request.storing.quantity =
        this.storing_quantity + this.shipment_difference_quantity;
    }
    // 入荷数量にチェックが入っていない場合、入荷数量を初期数量に戻す
    // 発注数量のチェックを外す
    if (!this.is_storing_modify) {
      this.update_request.storing.quantity = this.storing_quantity;
      this.update_request.storing.is_order_product_modify = false;
    }
  }

  // 出荷数量が変更された場合の倉庫移動数量挙動
  public movementsModify() {
    const latest_inventory =
      this.quantity_history.movements[0].arrival_stock.quantity;
    const oldest_inventory =
      this.quantity_history.movements[this.update_request.movements.length - 1]
        .arrival_stock.quantity;
    // 出荷数量の差分が出荷倉庫の現在庫より多い場合
    if (latest_inventory < this.shipment_difference_quantity) {
      this.is_movements_modify = true;
      this.movements_check_box = true;
      // 全ての倉庫移動数量に差分を足す
      this.update_request.movements.forEach((movement, index) => {
        movement.quantity =
          this.movements_quantity[index] + this.shipment_difference_quantity;
      });
      // 出荷数量の差分が最初の倉庫移動の現在庫より多いかつ入荷数量の現在庫より多い場合
      if (
        oldest_inventory < this.shipment_difference_quantity &&
        this.update_request.storing.stock.quantity <
          this.shipment_difference_quantity
      ) {
        this.is_storing_modify = true;
        this.storing_check_box = true;
        // 入荷数量に差分を足す
        this.update_request.storing.quantity =
          this.storing_quantity + this.shipment_difference_quantity;
      }
      // 出荷数量の差分が最初の倉庫移動の現在庫より少ない場合
      if (oldest_inventory >= this.shipment_difference_quantity) {
        this.is_storing_modify = false;
        this.storing_check_box = false;
        // 入荷数量を初期在庫に戻す
        // 発注数量のチェックを外す
        this.update_request.storing.quantity = this.storing_quantity;
        this.update_request.storing.is_order_product_modify = false;
      }
    }

    // 差分が出荷倉庫の現在庫を下回る場合
    if (latest_inventory >= this.shipment_difference_quantity) {
      this.movements_check_box = false;
      // 全ての倉庫移動数量を初期在庫に戻す
      this.update_request.movements.forEach((movement, index) => {
        movement.quantity = this.movements_quantity[index];
      });
      // 出荷数量の変更差分が0の場合
      if (this.shipment_difference_quantity == 0) {
        this.is_movements_modify = false;
        this.movements_check_box = false;
      }
      // 倉庫移動数量にチェックが入っている場合、全ての倉庫移動数量に出荷数量の変更差分を足す
      if (this.is_movements_modify) {
        this.update_request.movements.forEach((movement, index) => {
          movement.quantity =
            this.movements_quantity[index] + this.shipment_difference_quantity;
        });
      }
      // 倉庫移動数量にチェックが入っていない場合、全ての倉庫移動数量をリセット
      if (!this.is_movements_modify) {
        this.update_request.movements.forEach((movement, index) => {
          movement.quantity = this.movements_quantity[index];
        });
      }
      // 差分が最初の倉庫移動の現在庫を下回る場合
      if (oldest_inventory >= this.shipment_difference_quantity) {
        this.is_storing_modify = false;
        this.storing_check_box = false;
        // 入荷数量を初期在庫に戻す
        this.update_request.storing.quantity = this.storing_quantity;
        // 発注数量のチェックを外す
        this.update_request.storing.is_order_product_modify = false;
      }
    }
  }
}
